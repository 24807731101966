<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <foravila-logo />
    </b-link>
    <div class="misc-inner p-2 p-sm-3 text-center">
      <h1 class="fr-font-weight-super-bold">Ooops!</h1>
      <h2 class="mb-1">{{ $t("pages.notAuthorized.title") }}</h2>
      <div class="my-5">
        <b-img fluid :src="imgUrl" alt="Not found page" />
      </div>
      <b-button
        variant="primary"
        class="mb-1 btn-sm-block"
        :to="{ name: 'foravila-home' }"
      >
        {{ $t("pages.notAuthorized.buttonText") }}
      </b-button>
    </div>
  </div> </template
><template>
  <div id="page-not-found" class="misc-wrapper">
    <b-link class="brand-logo">
      <foravila-logo />
    </b-link>

    <div class="misc-inner p-2 p-sm-3 text-center">
      <h1 class="fr-font-weight-super-bold">Ooops!</h1>
      <h2 class="mb-1">{{ $t("pages.notFound.title") }}</h2>
      <div class="my-5">
        <b-img fluid :src="imgUrl" alt="Not found page" />
      </div>
      <b-button
        variant="primary"
        class="mb-1 btn-sm-block"
        :to="{ name: 'foravila-home' }"
      >
        {{ $t("pages.notFound.buttonText") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BLink, BImg, BButton } from "bootstrap-vue";
import ForavilaLogo from "@/layouts/foravila/components/ForavilaLogo.vue";

export default {
  components: {
    BLink,
    BImg,
    BButton,
    ForavilaLogo
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      imgUrl: require("@/assets/foravila/images/illustrations/not-found.svg")
    };
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
#page-not-found {
  img {
    width: 40rem;
    height: 40rem;
  }
}
</style>

<script>
import { BLink, BImg, BButton } from "bootstrap-vue";
import ForavilaLogo from "@/layouts/foravila/components/ForavilaLogo.vue";

export default {
  components: {
    BLink,
    BImg,
    BButton,
    ForavilaLogo
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      imgUrl: require("@/assets/images/pages/not-authorized.svg")
    };
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
